import request from "@/utils/request";

export function getLessonHour(data) {
  return request({
    url: "lesson_schedule/lesson_hour_get",
    method: "POST",
    data: data
  });
}

export function setLessonHour(data) {
  return request({
    url: "lesson_schedule/lesson_hour_set",
    method: "POST",
    data: data
  });
}

export function getLessonSchedule(data) {
  return request({
    url: "lesson_schedule/get",
    method: "POST",
    data: data
  });
}

export function resetLessonSchedule(data) {
  return request({
    url: "lesson_schedule/set",
    method: "POST",
    data: data
  });
}

export function clearLessonSchedule(data) {
  return request({
    url: "lesson_schedule/clear_class",
    method: "POST",
    data: data
  });
}

export function switchLessonSchedule(data) {
  return request({
    url: "lesson_schedule/switch",
    method: "POST",
    data: data
  });
}

export function setLessonExtra(data) {
  return request({
    url: "lesson_schedule/lesson_extra_set",
    method: "POST",
    data: data
  });
}

export function insertLessonSchedule(data) {
  return request({
    url: "lesson_schedule/insert",
    method: "POST",
    data: data
  });
}

export function deleteLessonSchedule(data) {
  return request({
    url: "lesson_schedule/delete",
    method: "POST",
    data: data
  });
}

export function createRoomAwor(data) {
  return request({
    url: "awor/create_room",
    method: "POST",
    data: data
  });
}

export function scheduleList(data) {
  return request({
    url: "schedule/schedule-absence",
    method: "POST",
    data: data
  });
}

export function updateEmail(data) {
  return request({
    url: "person/add_email",
    method: "POST",
    data: data
  });
}

export function createRoomZoom(data) {
  return request({
    url: "zoom/create_meeting",
    method: "POST",
    data: data
  });
}

export function createVidconLink(data) {
  return request({
    url: "lesson_schedule/set_vidcon_link",
    method: "POST",
    data: data
  });
}

export function endMeeting(data) {
  return request({
    url: "zoom/end_meeting",
    method: "POST",
    data: data
  });
}

export function getSecrets() {
  return request({
    url: "zoom/get_secrets",
    method: "POST"
  });
}

export function checkRoomStatus(data) {
  return request({
    url: "zoom/check_schedule_room_status",
    method: "POST",
    data: data
  });
}

export function lessonSameSub(data, isSubLesson) {
  return request({
    url: isSubLesson
      ? "lesson_schedule/get_lesson_same_sub"
      : "lesson_schedule/get_lesson_same_day",
    method: "POST",
    data: data
  });
}

export function updateLessonSubMeeting(data) {
  return request({
    url: "zoom/update_sub_lesson_meeting_id",
    method: "POST",
    data: data
  });
}
